<template>
  <div class="hello">
    <component-header></component-header>
    <router-view></router-view>
    <div class="qr-code">
      <div class="code-img">
        <div>如果有任何意见、问题或感兴趣的业务方向</div>
        <div>欢迎添加官方客服微信</div>
        <img src="http://imgv4.slimhand.com/Fmuq3sbe4RB1MHCkJ98HjfvrKyZj.png?imageView2" alt="">
        <div>或致电18030605489与我们联系</div>
      </div>
      <div class="code-hide">
        <div class="code-info">
          <div class="icon"></div>
          <div class="welcome">欢迎咨询</div>
        </div>
      </div>
    </div>
    <component-footer></component-footer>
  </div>
</template>

<script>
export default {
    name: "default",
    components: {
      "component-header": () => import("@/components/header/index.vue"),
      "component-footer": () => import("@/components/footer/index.vue"),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .hello{
    min-width: 1204px;
    padding-top: 80px;
  }
  .qr-code{
    display: flex;
    position: fixed;
    z-index: 999;
    bottom: 40%;
    right: 0;
    .code-img{
      width: 300px;
      background: #0D5898;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
      border-radius: 10px 0px 10px 10px;
      font-size: 13px;
      line-height: 20px;
      color:#fff;
      padding: 10px;
      position: absolute;
      left: 35px;
      transition: all .5s;
      img{
        width: 120px;
        height: 120px;
        margin: 10px 0;
      }
    }
    .code-info{
      width: 35px;
      height: 94px;
      background: #0D5898;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
      padding: 5px;
      border-radius: 5px 0px 0 5px;
      cursor: pointer;
    }
  }
  .qr-code:hover .code-img{
    left: -300px;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color:#0D5898;
  }
  @media only screen and (max-width: 767px) {
    .hello{
      min-width: 0;
    }
  }
</style>
